import type { DateStyles, TimeStyles } from '@/Types'
import { da } from 'date-fns/locale'
import {
  getUnixTime,
  format,
  isSameDay,
  isSameMonth,
  isSameYear,
} from 'date-fns'
import { useI18n } from 'vue-i18n'

const capitalizeFirstLetter = (value: string) => {
  return value.charAt(0).toUpperCase() + value.slice(1)
}

const formatToBrowserDateTime = (
  date: Date,
  options?: { dateStyle?: DateStyles; timeStyle?: TimeStyles }
) => {
  // It uses the navigator language selection. Browsers can not access to the
  // system settings because of security concerns.
  const dateFormat = new Intl.DateTimeFormat(navigator.language, options)
  return dateFormat.format(date).split(' ').map(capitalizeFirstLetter).join(' ')
}

const convertToDate = (value: Date | number): Date => {
  return typeof value === 'number' ? toDate(value) : value
}

export const getFullMonthName = (date: any) => {
  const dateFormat = new Intl.DateTimeFormat(navigator.language, {
    month: 'long',
  })
  return dateFormat.format(date)
}

/**
 * Convert unix timestamp to Date object
 *
 * @param unix number
 * @returns Date
 * @example
 *  <script setup lang="ts">
 *    const date: Date = toDate(1697700327)
 *  </script>
 */
export const toDate = (unix: number) => {
  return new Date(unix * 1000)
}

/**
 * Convert Date object to unix timestamp
 *
 * @param unix number
 * @returns Date
 * @example
 *  <script setup lang="ts">
 *    const value: number = toUnix(new Date())
 *  </script>
 */
export const toUnix = (date: Date) => {
  return Math.round(getUnixTime(date))
}

export const toBrowserDate = (
  date: Date | number,
  dateStyle: DateStyles = 'short'
) => {
  return formatToBrowserDateTime(convertToDate(date), { dateStyle })
}

export const toBrowserTime = (
  date: Date | number,
  timeStyle: TimeStyles = 'short'
) => {
  return formatToBrowserDateTime(convertToDate(date), { timeStyle })
}

export const toDayName = (date: number) => {
  const name = format(toDate(date), 'iiii', {
    locale: da,
  })
  return name.charAt(0).toUpperCase() + name.slice(1)
}

export const toWeekNumber = (date: number) => {
  return format(toDate(date), 'ww')
}

export const toAPIDate = (date: Date): string => {
  return date.toISOString().split('.')[0] + 'Z'
}

const monthShortTitle = (date: Date) => {
  const { t } = useI18n()
  const key = format(date, 'MMMM').toLowerCase()
  return t(`dates.months.${key}`)
}

const toDateRange = (start: Date, end: Date) => {
  const first = format(start, 'HH:mm')
  const second = format(end, 'HH:mm')
  if (first === second) {
    return first
  }

  return `${first}-${second}`
}

export const formatEventDate = (start: Date, end: Date) => {
  const now = new Date()

  const currentYear = isSameYear(now, start)

  const startMonthTitle = monthShortTitle(start)
  const endMonthTitle = monthShortTitle(end)

  if (isSameDay(start, end)) {
    if (currentYear) {
      return `${format(start, 'd')} ${startMonthTitle}, ${toDateRange(start, end)}`
    } else {
      return `${format(start, 'd')} ${startMonthTitle} ${format(start, 'yyyy')}, ${toDateRange(start, end)}`
    }
  }

  if (isSameMonth(start, end)) {
    if (currentYear) {
      return `${format(start, 'd')} ${startMonthTitle} - ${format(end, 'd')}`
    } else {
      return `${format(start, 'd')} ${startMonthTitle} - ${format(end, 'd')} ${endMonthTitle} ${format(end, 'yyyy')}`
    }
  }

  if (isSameYear(start, end)) {
    return `${format(start, 'd')} ${startMonthTitle} - ${format(end, 'd')} ${endMonthTitle}`
  }

  return `${format(start, 'd')} ${startMonthTitle} ${format(start, 'yyyy')} - ${format(end, 'd')} ${endMonthTitle} ${format(end, 'yyyy')}`
}
