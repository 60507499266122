<script setup lang="ts">
import { defineAsyncComponent } from 'vue'
import { useGeneralStore } from '@/stores/GeneralStore'
import NavigationItemComponent from '@/components/header/NavigationItemComponent.vue'
import OnBehalfMessage from '@/components/shared/OnBehalfMessage.vue'
import LogoComponent from './LogoComponent.vue'
import { useRouter } from 'vue-router'

const generalStore = useGeneralStore()
const router = useRouter()

function getComponentDefinition(name: string) {
  return defineAsyncComponent(() => import(`../header/${name}.vue`))
}

const handleLogoClick = () => {
  router.push('/')
}
</script>

<template>
  <nav
    :class="[
      'fixed bottom-0 left-0 w-screen overflow-hidden bg-neutral0 h-88 flex justify-between py-4 px-40 shadow-smallBottom z-30 desktop:top-0 desktop:w-full desktop:h-72',
      generalStore.hasFooter ? 'invisible desktop:visible' : '',
    ]"
  >
    <div class="w-1/4 flex justify-center invisible desktop:visible">
      <LogoComponent @click="handleLogoClick" />
    </div>
    <div class="w-2/4 flex justify-center items-center mb-2 desktop:mb-0">
      <component :is="getComponentDefinition('NavigationComponent')" />
    </div>
    <div class="w-1/4 flex justify-end items-center gap-2">
      <OnBehalfMessage />
      <div class="invisible desktop:visible">
        <router-link
          :to="{ name: 'help' }"
          class="block focusVisible rounded-8"
        >
          <NavigationItemComponent
            icon="circle-question"
            :text="$t('global.help')"
            :name="'help'"
          />
        </router-link>
      </div>
    </div>
  </nav>
</template>
