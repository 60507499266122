<script setup lang="ts">
import type { BaseDialogProps } from '@/Interfaces'
import BaseDialog from './BaseDialog.vue'
import ButtonComponent from '@/components/shared/ButtonComponent.vue'
import { ref, toRefs } from 'vue'
import FunctionDialogHeader from './FunctionDialogHeader.vue'
import { useGeneralStore } from '@/stores/GeneralStore'

interface Props extends BaseDialogProps {
  isClosable?: boolean
  title: string
  description?: string
  titleCenterOnMobile?: boolean
}

const props = withDefaults(defineProps<Props>(), {
  isClosable: true,
})

const { isClosable } = toRefs(props)

defineEmits<{
  (e: 'close'): void
}>()

const generalStore = useGeneralStore()
const contentRef = ref<HTMLDivElement>()
const hasScroll = ref(false)
const scrollPosition = ref(0)
const isScrollBottom = ref(false)

const handleOpen = () => {
  if (contentRef.value) {
    const { scrollHeight, clientHeight } = contentRef.value
    hasScroll.value = scrollHeight > clientHeight
  }
}

const handleScroll = () => {
  if (contentRef.value) {
    const { scrollTop, scrollHeight, offsetHeight } = contentRef.value
    scrollPosition.value = scrollTop
    isScrollBottom.value = scrollTop === scrollHeight - offsetHeight
  }
}
</script>

<template>
  <BaseDialog :open="open" :backdrop-type="backdropType" @on-open="handleOpen">
    <div
      class="fixed top-0 left-0 bg-neutral0 w-screen h-dvh desktop:w-540 desktop:unset-position desktop:unset-height"
    >
      <div
        class="flex flex-col justify-between h-dvh desktop:block desktop:unset-height"
      >
        <div v-if="isClosable" class="p-16 pb-8">
          <slot name="close-box">
            <ButtonComponent
              type="secondary"
              size="medium"
              icon="times"
              @click="$emit('close')"
              @keypress="$emit('close')"
            />
          </slot>
        </div>
        <FunctionDialogHeader
          v-if="generalStore.breakpoint === 'desktop'"
          :scroll-position="scrollPosition"
          :is-closable="isClosable"
          :title="title"
          :description="description"
          :title-center-on-mobile="titleCenterOnMobile || false"
          show-border-on-scroll
        />
        <div
          class="px-24 py-12 desktop:max-h-450 overflow-y-scroll flex-grow"
          ref="contentRef"
          @scroll="handleScroll"
        >
          <FunctionDialogHeader
            v-if="generalStore.breakpoint === 'mobile'"
            :scroll-position="scrollPosition"
            :is-closable="isClosable"
            :title="title"
            :description="description"
            :title-center-on-mobile="titleCenterOnMobile || false"
            :show-border-on-scroll="false"
          />
          <slot></slot>
        </div>
        <div
          :class="[
            'px-24 py-16 bg-neutral0 transition duration-200 border-neutral300',
            hasScroll && !isScrollBottom ? 'border-t' : '',
          ]"
        >
          <slot name="actions"></slot>
        </div>
      </div>
    </div>
  </BaseDialog>
</template>
